import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import WhatsApp from './socialPage/WhatsApp';
import TeleG from './socialPage/TeleG';
import ChatSup from './socialPage/ChatSup';
import Home from './page/Home';

const App = () => {
return (
<BrowserRouter>
<Routes>
<Route path='/' element={<Home />} />
<Route path='/whatsapp' element={<WhatsApp />} />
<Route path='/telegram' element={<TeleG />} />
<Route path='/support' element={<ChatSup />} />
</Routes>
</BrowserRouter>
)
}

export default App;
