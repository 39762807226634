import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
return (
<>
<div className='pageDiv'>
<div className='pageContentWrap'>
<div className='pageContent'>
<div className='menuArea'>
<div className='menuContent'>
<ul>
<li><Link to='/support'>support</Link></li>
<li><Link to='/whatsapp'>whatsapp</Link></li>
<li><Link to='/telegram'>telegram</Link></li>
</ul>
</div>
</div>

<div className='logoAreaWrap'>
<div className='logoArea'>
<img src='/images/layBack.png' alt='logo' />
</div>
</div>

<div className='descriptionCon'>
<h1>india's most trusted sportsbook service provider</h1>
<div className='conicDiv'><h2>since 2008</h2></div>
<div className='respDiv'><h4>visit now</h4> <h4>www.layback247.online</h4></div>
</div>

<div className='sportsCardsDivWrap'>
<div className='sportsCardsDiv'>
<Link to='https://wa.me/+919122132488' target='blank'>
<div className='sportsCards'>
<img src='/images/cricket.png' alt='cricket' />
<span>cricket id</span>
<button>get id now</button>
</div>
</Link>
<Link to='https://wa.me/+919122132488' target='blank'>
<div className='sportsCards'>
<img src='/images/football.png' alt='cricket' />
<span>soccer id</span>
<button>get id now</button>
</div>
</Link>
<Link to='https://wa.me/+919122132488' target='blank'>
<div className='sportsCards'>
<img src='/images/tennis.png' alt='cricket' />
<span>tennis id</span>
<button>get id now</button>
</div>
</Link>
<Link to='https://wa.me/+919122132488' target='blank'>
<div className='sportsCards'>
<img src='/images/casino.png' alt='cricket' />
<span>casino id</span>
<button>get id now</button>
</div>
</Link>
<Link to='https://wa.me/+919122132488' target='blank'>
<div className='sportsCards'>
<img src='/images/board-game.png' alt='cricket' />
<span>virtual id</span>
<button>get id now</button>
</div>
</Link>
</div>
</div>

<div className='descriptionConsm'>
<h4>layback offers</h4>
<hr />
<p>We provide various sports exchanges with more than 1000+ Platforms to play on different types of sports , games and casinos. We Provide all the levels of IDs, e.g: Admin , Master, Player ID. We Provide White Panels of all Websites.</p>
</div>

<div className='chatOptDiv'>
<h2>create account?</h2>
<Link to='https://wa.me/+919122132488' target='blank'><button><img src='/images/whatsappWhite.png' alt='whatsappWhite' /> chat on whats'app</button></Link>
<Link to='https://layback247.online/home' target='blank'><button><img src='/images/log-in.png' alt='log-in' /> signup now</button></Link>
</div>

<div className='followSec'>
<h2>follow us on</h2>
<hr />
<div className='followSocial'>
<div className='followImg'>
<Link to='https://www.facebook.com/profile.php?id=61570482546556' target='blank'><img src='/images/facebookSo.png' alt='facebookSo' /></Link>
</div>
<div className='followImg'>
<Link to='https://t.me/layback_365' target='blank'><img src='/images/telegramSo.png' alt='telegramSo' /></Link>
</div>
<div className='followImg'>
<Link to='https://www.instagram.com/layback2472020/' target='blank'><img src='/images/instagramSo.png' alt='facebookSo' /></Link>
</div>
</div>
</div>

</div>
</div>
</div>
</>
)
}

export default Home;
